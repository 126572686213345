import { PageProps } from "gatsby";

import BlogPage from "../../components/BlogPage";
import withPages from "../../hoc/withPages";

function IndexPage(props: PageProps) {
  return withPages(BlogPage)({
    title: "deeple Blog",
    description:
      "deeple Blog แหล่งรวมความรู้สำหรับคนทำธุรกิจออนไลน์ และบทความที่ช่วยให้คุณใช้งานระบบจัดการร้านค้าออนไลน์ deeple ได้อย่างเต็มประสิทธิภาพ",
    ...props,
  });
}

export default IndexPage;
