import React, { useMemo } from "react";

import { BlogCardItem } from "../../types/Blog";
import BlogCard, { BlogCardProps } from "../BlogCard";

interface RendererPaginateProps {
  currentPage: number;
  numPages: number;
  handlePageClick: (pageIndex: number) => void;
}

interface BlogCardGroupProps extends RendererPaginateProps {
  items: BlogCardItem[];
  onRedirectBlog: (slug: string) => void;
}

const BlogCardWithLine = ({ isFirst, item, handleClick }: BlogCardProps) => {
  return (
    <>
      <BlogCard isFirst={isFirst} item={item} handleClick={handleClick} />
      <div className="my-9 md:my-12 lg:hidden border border-solid border-custom-light-grey" />
    </>
  );
};

const RendererPaginate = ({
  currentPage,
  numPages,
  handlePageClick,
}: RendererPaginateProps): JSX.Element[] => {
  const rows: JSX.Element[] = [];
  for (let i = 1; i <= numPages; i++) {
    let item;
    if (Math.abs(i - currentPage) === 4) {
      item = <span className="mr-4">...</span>;
    } else if (Math.abs(i - currentPage) <= 3) {
      item = (
        <div
          onClick={() => handlePageClick(i)}
          className={`mr-4 cursor-pointer font-graphikthloop ${
            currentPage === i && "text-white bg-black w-6 h-6 text-center"
          }`}
        >
          {i}
        </div>
      );
    }
    rows.push(item);
  }
  return rows;
};

const BlogCardGroup = ({
  items,
  currentPage,
  numPages,
  handlePageClick,
  onRedirectBlog,
}: BlogCardGroupProps) => {
  const latestItem = useMemo(() => {
    return items?.shift();
  }, [items]);
  return (
    <div className="w-full">
      {latestItem && (
        <div className="lg:mb-7">
          <BlogCardWithLine
            isFirst
            item={latestItem}
            handleClick={onRedirectBlog}
          />
        </div>
      )}
      <div className="lg:grid lg:grid-cols-2 lg:gap-x-5 lg:gap-y-10">
        {items?.map((item) => (
          <div key={item.id}>
            <BlogCardWithLine item={item} handleClick={onRedirectBlog} />
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-12 lg:mt-24 mb-12 lg:mb-0 ">
        {RendererPaginate({ currentPage, numPages, handlePageClick })}
      </div>
    </div>
  );
};

export default BlogCardGroup;
