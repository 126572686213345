import dayjs from "dayjs";
import React from "react";

import BankingImage from "../../../static/image/BGHeader.png";
import { BlogCardItem } from "../../types/Blog";

export interface BlogCardProps {
  isFirst?: boolean;
  item: BlogCardItem;
  handleClick: (slug: string) => void;
}

const BlogCard: React.FC<BlogCardProps> = ({
  isFirst,
  item,
  handleClick,
}: BlogCardProps) => {
  const author = {
    firstname: item.author?.firstname || item.createdBy?.firstname,
    lastname: item.author?.lastname || item.createdBy?.lastname,
    avatar: item.author?.avatar || BankingImage,
  };

  return (
    <div
      className="relative bg-white cursor-pointer"
      onClick={() => handleClick(item.slug)}
    >
      <img src={item.coverURL} alt="cover-image" />
      <div className="lg:absolute my-6 md:my-7 lg:mt-0 lg:top-5 lg:left-5">
        <div className="relative flex">
          {item.categories?.map((cat) => (
            <div
              key={cat.id}
              className={`w-fit-content ml-3 first:ml-0 text-white bg-black px-3 py-1 rounded font-graphikthloop ${
                !isFirst ? "lg:text-xs" : ""
              }`}
            >
              {cat.label}
            </div>
          ))}
        </div>
      </div>
      <div className="text-lg lg:text-2xl lg:mt-6 font-medium clamp-line max-h-full md:max-h-14 lg:max-h-16 lg:h-16">
        {item.header}
      </div>
      {(!isFirst || item.description) && (
        <div className="text-base lg:text-lg mt-2.5 md:mt-3 lg:mt-3.5 font-graphikthloop max-h-full md:max-h-12 lg:max-h-14 lg:h-14 clamp-line">
          {item.description}
        </div>
      )}
      <div className="flex mt-4 md:mt-7 align-center">
        <img className="w-10 h-10 rounded-full" src={author.avatar} />
        <div className="text-base ml-3">
          <div>
            {author.firstname} {author.lastname}
          </div>
          <div className="text-custom-dark-grey font-graphikthloop">
            {dayjs(item.updatedAt).format("DD MMM YYYY")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
