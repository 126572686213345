import React, { useMemo } from "react";
import qs from "qs";
import { useState, useEffect } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";

import Footer from "../Footer";
import TopNavWhite from "../TopNavWhite";
import "./index.css";
import BlogCardGroup from "../BlogCardGroup";
import MenuList from "../MenuList";
import SquareImage from "../../../static/image/COD.png";
import MenuListWithImage from "../MenuList/MenuListWithImage";
import { BlogCardItem, Categories, Content } from "../../types/Blog";
import { MenuItem } from "../MenuList/type";
import getStrapiContent from "../../../functions/api/strapi";
import BlogSearchInput from "../BlogSearchInput";

const containerBlogStyle = "relative mx-auto max-w-max-screen";

const transformCategoriesToMenuItems = (
  categories: Categories[]
): MenuItem[] => {
  if (categories) {
    return categories.map((category) => ({
      id: category.id,
      value: category.attributes?.value,
      label: category.attributes?.label,
    }));
  }
  return [];
};

const transformContentToBlog = (contents: Content[]): BlogCardItem[] => {
  return contents.map((content) => ({
    id: content.id,
    header: content.attributes?.header,
    description: content.attributes?.description,
    createdAt: content.attributes?.createdAt,
    updatedAt: content.attributes?.updatedAt,
    createdBy: content.attributes?.createdBy,
    categories: transformCategoriesToMenuItems(
      content.attributes?.categories?.data
    ),
    slug: content.attributes?.slug,
    coverURL: `${content.attributes?.cover.data?.attributes?.url}`,
    author: {
      firstname: content.attributes?.author.data?.attributes.firstname,
      lastname: content.attributes?.author.data?.attributes.lastname,
      avatar:
        content.attributes?.author.data?.attributes.avatar.data.attributes.url,
    },
  }));
};

const BlogPage: React.FC = ({ location }) => {
  const categoryData = useStaticQuery(graphql`
    query blogPageQuery {
      allStrapiCategory {
        nodes {
          id
          label
          value
        }
      }
    }
  `);
  const [category, setCategory] = useState<MenuItem | undefined>({
    id: 0,
    value: "all",
    label: "All",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  const [contents, setContents] = useState<BlogCardItem[]>([]);
  const categories = [
    { id: 0, value: "all", label: "All" },
    ...(categoryData?.allStrapiCategory?.nodes || []),
  ];

  const getContent = async ({
    isOption,
    searchParams,
  }: {
    isOption: boolean;
    searchParams?: string;
  }) => {
    try {
      const query = qs.stringify(
        {
          filters: {
            categories: {
              value: {
                $in: category?.value !== "all" ? category?.value : [],
              },
            },
            header: {
              $containsi: searchParams,
            },
          },
          sort: {
            updatedAt: "desc",
          },
          pagination: {
            page: currentPage,
            pageSize: 9,
          },
          populate: ["categories", "cover", "author.avatar", "createdBy"],
        },
        {
          encodeValuesOnly: true,
        }
      );

      const result = await getStrapiContent(query);
      if (result) {
        if (isOption) {
          return result.data;
        } else {
          const blogContent = transformContentToBlog(result.data);
          setContents(blogContent);
          setNumPages(result.meta?.pagination?.pageCount);
        }
      }
    } catch (e) {
      console.log("e:", e);
    }
  };

  useEffect(() => {
    const searchParams = location.state?.searchParams;
    getContent({ isOption: false, searchParams });
    if (searchParams) {
      // clear location state
      navigate(".", { state: "", replace: true });
      setCategory(undefined);
    }
  }, [category, currentPage]);

  const handleClickMenuList = (item: MenuItem) => {
    setCategory(item);
    setCurrentPage(1);
  };

  const handlePageClick = (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };

  const defaultCategoryIndex = useMemo(() => {
    const categoryIndex = categories.findIndex(
      (cat) => cat.value === category?.value
    );
    return categoryIndex;
  }, [categories]);

  const onRedirectBlog = (slug) => {
    navigate(`/blog/${slug}`);
  };

  return (
    <>
      <div className="bg-white">
        <div className="center-container" id="blog">
          <TopNavWhite
            SearchComponent={() =>
              BlogSearchInput({ getContent, isBlogContent: false })
            }
          />
        </div>
      </div>
      <div className={`${containerBlogStyle} layout-blog`}>
        <div className="header-title flex flex-col justify-center">
          <h1 className="dashboard-heading-1 mt-4 md:my-4 font-weight-600">
            deeple Blog
          </h1>
          <h2 className="dashboard-heading-2 my-4 md:my-4 pt-2  font-weight-600">
            แหล่งรวมความรู้สำหรับคนทำธุรกิจออนไลน์
          </h2>
        </div>
      </div>
      <div className="bg-white">
        <div className={containerBlogStyle}>
          <div className="lg:flex pt-11 md:pt-12 lg:pb-28 lg:pt-20 mx-4 md:mx-12 lg:mx-8">
            <BlogCardGroup
              items={contents}
              currentPage={currentPage}
              numPages={numPages}
              handlePageClick={handlePageClick}
              onRedirectBlog={onRedirectBlog}
            />
            <div className="pb-16 md:pb-24 lg:pb-0 lg:ml-14">
              <MenuList
                title="เลือกหัวข้อที่คุณสนใจ"
                items={categories}
                initialIndex={defaultCategoryIndex}
                handleClick={handleClickMenuList}
              />
              <div className="my-9 md:my-12 lg:hidden border border-solid border-custom-light-grey" />
              {/* <MenuListWithImage
                title="Popular"
                handleClick={(slug) => {
                  console.log(slug);
                }}
                items={[
                  {
                    id: 0,
                    value:
                      "เปลี่ยนทุกการจัดส่งให้ง่าย ไม่ขาดทุนและครอบคลุมทุกรูปแบบการซื้อขาย",
                    label:
                      "เปลี่ยนทุกการจัดส่งให้ง่าย ไม่ขาดทุนและครอบคลุมทุกรูปแบบการซื้อขาย",
                    image: SquareImage,
                    slug: "test-1",
                  },
                  {
                    id: 1,
                    value:
                      "เปลี่ยนทุกการจัดส่งให้ง่าย ไม่ขาดทุนและครอบคลุมทุกรูปแบบการซื้อขาย",
                    label:
                      "เปลี่ยนทุกการจัดส่งให้ง่าย ไม่ขาดทุนและครอบคลุมทุกรูปแบบการซื้อขาย",
                    image: SquareImage,
                    slug: "test-1",
                  },
                  {
                    id: 2,
                    value:
                      "เปลี่ยนทุกการจัดส่งให้ง่าย ไม่ขาดทุนและครอบคลุมทุกรูปแบบการซื้อขาย",
                    label:
                      "เปลี่ยนทุกการจัดส่งให้ง่าย ไม่ขาดทุนและครอบคลุมทุกรูปแบบการซื้อขาย",
                    image: SquareImage,
                    slug: "test-1",
                  },
                  {
                    id: 3,
                    value:
                      "เปลี่ยนทุกการจัดส่งให้ง่าย ไม่ขาดทุนและครอบคลุมทุกรูปแบบการซื้อขาย",
                    label:
                      "เปลี่ยนทุกการจัดส่งให้ง่าย ไม่ขาดทุนและครอบคลุมทุกรูปแบบการซื้อขาย",
                    image: SquareImage,
                    slug: "test-1",
                  },
                ]}
              /> */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default BlogPage;
