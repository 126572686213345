import React, { useState } from "react";
import { MenuItem } from "./type";

type MenuListProps = {
  title: string;
  items: MenuItem[];
  initialIndex: number;
  handleClick: (item: MenuItem) => void;
};

const MenuList: React.VFC<MenuListProps> = ({
  title,
  items,
  initialIndex,
  handleClick,
}: MenuListProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(initialIndex);

  const handleMenu = (index) => {
    setActiveIndex(index);
    const itemByIndex = items[index];
    handleClick(itemByIndex);
  };

  return (
    <div style={{ width: "296px" }}>
      <p className="my-2.5 font-medium text-2xl">{title}</p>
      <div className="flex flex-col">
        {items?.map((item, index) => (
          <a
            className={`mb-2.5 text-lg cursor-pointer ${
              activeIndex === index ? "font-weight-600" : "font-graphikthloop"
            } last:mb-0`}
            key={item.id}
            href="#blog"
            onClick={() => handleMenu(index)}
          >
            {item.label}
          </a>
        ))}
      </div>
    </div>
  );
};

export default MenuList;
